// import '../styles/globals.css'
// import '../styles/index.css';
import '../polyfill'
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import 'config/config.styles.scss';
import { ChakraProvider } from '@chakra-ui/react';
import { ggSentry } from 'config/sentry';
import { ggAnalytics } from 'config/ga';
import ReloadApp from 'components/ReloadApp';
import { chakraTheme } from '@/config/chakra';
import BannedStatusProvider from 'components/BannedStatusWrapper';
import Head from 'next/head';
import AppErrorBoundary from '@/components/ErrorBoundary';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
ggSentry.initialize(process.env.NEXT_PUBLIC_SENTRY);
ggAnalytics.initialize(process.env.NEXT_PUBLIC_GA);
function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    ggAnalytics.firePageView(router.asPath);
  }, [router.asPath]);
  return (
    <>
      <ReloadApp />
      <ChakraProvider theme={chakraTheme}>
        <AppErrorBoundary>
          <BannedStatusProvider>
            <Head>
              <title>{process.env.NEXT_PUBLIC_PAGE_TITLE}</title>
              <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <Component {...pageProps} />
          </BannedStatusProvider>
        </AppErrorBoundary>

      </ChakraProvider>
    </>
  )
}

export default MyApp
// reportWebVitals()
